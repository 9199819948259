<template>
    <div class="w-p-100 h-p-100 bg-f3">
      <van-form
        class="h-p-100 scroll-y w-p-100"
        @submit="onSubmit"
        ref="detailSubmit"
      >
        <van-cell-group inset class="h-calc-45 scroll-y margin-0 bg-transparent">
            <van-field
            is-link 
            v-model="state.type"
            name="type"
            label="风险研判类型"
            @click="picker.type = true"
            placeholder="必填项"
            :rules="[{ required: true, message: '必填项不能为空' }]"
            />

            <van-field
            is-link 
            v-model="state.sys_class_id"
            name="sys_class_id"
            label="班组"
            @click="picker.sys_class_id = true"
            placeholder="必填项"
            :rules="[{ required: true, message: '必填项不能为空' }]"
            />
            
            <van-field  
            v-model="detailForm.detail"
            name="detail"
            label="风险研判内容" 
            placeholder="必填项"
            :rules="[{ required: true, message: '必填项不能为空' }]"
            />

            <van-field
            is-link 
            v-model="state.cems_choice"
            name="cems_choice"
            label="默认选项"
            @click="picker.cems_choice = true"
            placeholder="必填项"
            :rules="[{ required: true, message: '必填项不能为空' }]"
            />

            <van-field
            is-link 
            v-model="state.isenable"
            name="isenable"
            label="是否启用"
            @click="picker.isenable = true"
            placeholder="必填项"
            :rules="[{ required: true, message: '必填项不能为空' }]"
            />
            
            <van-popup v-model:show="picker.type" position="bottom">
            <van-picker
                :columns="options.type"
                @confirm="
                data => {
                    confirm(data, 'type');
                }
                "
                :columns-field-names="{ text: 'PARAMETER_NAME', value: 'PARAMETER_VALUE' }"
                @cancel="picker.type = false"
            />
            </van-popup>

            <van-popup v-model:show="picker.sys_class_id" position="bottom">
            <van-picker
                :columns="options.sys_class_id"
                @confirm="
                data => {
                    confirm(data, 'sys_class_id');
                }
                "
                :columns-field-names="{ text: 'label', value: 'value' }"
                @cancel="picker.sys_class_id = false"
            />
            </van-popup>

            <van-popup v-model:show="picker.cems_choice" position="bottom">
            <van-picker
                :columns="options.cems_choice"
                @confirm="
                data => {
                    confirm(data, 'cems_choice');
                }
                "
                :columns-field-names="{ text: 'PARAMETER_NAME', value: 'PARAMETER_VALUE' }"
                @cancel="picker.cems_choice = false"
            />
            </van-popup>

            <van-popup v-model:show="picker.isenable" position="bottom">
            <van-picker
                :columns="options.isenable"
                @confirm="
                data => {
                    confirm(data, 'isenable');
                }
                "
                :columns-field-names="{ text: 'label', value: 'value' }"
                @cancel="picker.isenable = false"
            />
            </van-popup>

            <van-calendar
            v-model:show="showCalendar"
            @confirm="calendarOnConfirm"
            :min-date="new Date('2000-01-01')"
            />
        </van-cell-group>
        <div class="flex">
          <van-button
            block
            type="primary"
            native-type="submit"
            :disabled="btnLoading"
            :loading="btnLoading"
            >保存</van-button
          >
          <van-button
            block
            type="primary"
            @click="copySave()"
            :disabled="btnLoading"
            :loading="btnLoading"
            >复制</van-button
          >
          <van-button
            block
            type="default"
            class="bg-808080 color-fff"
            @click="goBack()"
            >返回</van-button
          >
        </div>
      </van-form>
    </div>
</template>
<script setup>
/* eslint-disable */
import { onMounted,reactive, ref} from 'vue';
import http from '../../../api/http';
import {Toast,Notify,Dialog ,ImagePreview} from 'vant'
import { remove } from '@vue/shared';
import dayjs from 'dayjs';
import { useRouter,useRoute } from 'vue-router';

const router = useRouter();
const route = useRoute();  

import global from '../../../api/global';
const permission = reactive(global.getPagePermission(route.meta.parent));

const showCalendar = ref(false)
const calendarOnConfirm = (date) =>{
    detailForm.risk_judgement_date =  dayjs(date).format('YYYY-MM-DD');//`${date.getFullYear()}-${date.getMonth() + 1}-${date.getDate()}`;
    state.risk_judgement_date =  dayjs(date).format('YYYY-MM-DD');
    showCalendar.value = false;
}
const goBack = ()=>{
        router.push({
            path:'/RST_CONFIG',
            query:route.query
        })
}
const detailSubmit = ref(null);
const detailForm = reactive({
    id:'',
    type:'',
    sys_class_id:'',
    detail:'',
    isenable:1,
    cems_choice:'',
    create_by:'',
    create_time:'',
    update_time:'',
    update_by:''
});
const state = reactive({
    id:'',
    type:'',
    sys_class_id:'',
    detail:'',
    isenable:'启用',
    cems_choice:'',
    create_by:'',
    create_time:'',
    update_time:'',
    update_by:''
});

const loading = ref(false);

const options = reactive({
    type:[],
    sys_class_id:[],
    cems_choice:[],
    isenable:[
        {label:'启用',value:1},{label:'停用',value:0}
    ]
});
const picker = reactive({
    type:false,
    sys_class_id:false,
    cems_choice:false,
    isenable:false,
});
const confirm = (data,type) =>{
    if(type=='type'||type=='cems_choice'){
        detailForm[type] = data.PARAMETER_VALUE;
        state[type] = data.PARAMETER_NAME;
    }else{
        detailForm[type] = data.value;
        state[type] = data.label;
    }
    
    picker[type] = false;        
}

const getSelectList = () =>{
    http.get({
        method:'listMultiPardata',
        t:'RISK_JUDGEMENT_SETTING',
        prop:'sys_class_id'
    },true)
    .then(res=>{
        options.sys_class_id =  res.resultData.sys_class_id;
    })
    http.get({
        method:'query',
        queryId:3008,
        parameter_types:"RISK_JUDGEMENT_CHOICE,RISK_JUDGEMENT_TYPE"
    },true)
    .then(res=>{
        options.type = res.resultData.RISK_JUDGEMENT_TYPE;
        options.cems_choice = res.resultData.RISK_JUDGEMENT_CHOICE;
        if(route.params.id!=0){
            getDetail(route.params.id)
        }
    })
}


const btnLoading = ref(false);
const onSubmit = () =>{
    Dialog.confirm({
        message:'是否确认保存?',
    })
    .then(()=>{
        btnLoading.value = true;
        detailForm.method = 'query';
        detailForm.queryId = "3005";
        http.post(detailForm,true)
        .then(res=>{
            btnLoading.value = false;
            Notify({ type: 'success', message: '保存成功' });
            replaceRoute(res.resultData.map)
            getDetail(res.resultData.map);
        })
        .catch(()=>{
            btnLoading.value = false;
        });
    })
    .catch(()=>{
    });
}

// 详情
const getDetail = (id) =>{
    loading.value = true
    http.get({
        method:'query',
        queryId:3004,
        id:id
    },true,false)
    .then(res=>{
        loading.value = false
        var dMap = res.resultData.map;
        Object.keys(detailForm).map(key=>{
            if(key!='method'&&key!='t'&&key!='queryId'){
                detailForm[key] = dMap[key]
                state[key] = dMap[key]
            }
        });
        getStateDisplay()
    })
}
//处理回显
const getStateDisplay = () =>{
    state.type = options.type.find(e=>e.PARAMETER_VALUE==detailForm.type).PARAMETER_NAME
    state.sys_class_id = options.sys_class_id.find(e=>e.value==detailForm.sys_class_id).label
    state.cems_choice = options.cems_choice.find(e=>e.PARAMETER_VALUE==detailForm.cems_choice).PARAMETER_NAME
    state.isenable = options.isenable.find(e=>e.value==detailForm.isenable).label
}
// 新增编辑之后执行事件
const replaceRoute = (id) =>{
    router.replace({
        name:'RstConfigDetail',
        params:{
            id:id
        }
    })
}

// 复制
const copySave = () =>{
    Dialog.confirm({
        message:'是否确认复制?',
    })
    .then(()=>{
        detailForm.id = '';
    })
    .catch(()=>{
    });
}

onMounted(()=>{
    getSelectList();
});
  </script>